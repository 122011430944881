<template>
  <div class="card-info">
    <div class="card-title">上传营业执照信息</div>
    <div style="text-align: center;margin: 20px 0.5rem;display: flex;justify-content: center; position:relative" @click="chooseImg">
        <van-image ref="previewImage" :src=perviewImage style="width: 6.5rem;height: 4.33rem">
        </van-image>
        <div style="position: absolute;top:50%;transform:translateY(-50%);width: 1.6rem;height: 1.6rem;background-color: rgba(0, 0, 0, 0.3);border-radius: 1rem;display: flex;align-items: center;justify-content: center">
            <img src="@/assets/img/stationagent/camera.png" ref="previewFrontBtn" style="width: 0.88rem;height: 0.76rem"/>
        </div>
        <van-uploader ref="bankImgup" max-count="1" style="display: none" v-model="fileList" :after-read="afterRead" />
    </div>
    <van-field v-model="ownerName" :formatter="nameFormatter" label="经营者/法定代表人" input-align="right"
                label-width="125" :disabled="!isCanInput" placeholder="根据营业执照自动识别"/>
    <van-field v-model="companyName" :formatter="nameFormatter" label="名称"
                label-width="40" input-align="right" :disabled="!isCanInput" placeholder="根据营业执照自动识别" />
    <van-field v-model="creditCode" label="统一社会信用代码"
                label-width="125" maxlength="18" input-align="right" :disabled="!isCanInput" placeholder="根据营业执照自动识别"/>

    <div class="nobl" @click="choosenobl" v-if="isSpecial == 1">
      <div class="nobl-title">无营业执照？点击上传其他证明</div>
      <van-image :src='noblImg' style="width: 3.4rem;height: 2rem; margin-top:0.3rem; border-radius:0.16rem"></van-image>
      <van-uploader ref="noblImgup" max-count="1" style="display: none" v-model="fileListbl" :after-read="afterReadbl" />
    </div>


    <div class="publicBtnDiv">
      <van-button class="publicBtnActive" type="info" color="#3F7C53" @click="commitAction">下一步</van-button>
    </div>

  </div>
</template>

<script>
    import { sendBusinessLicenseOcr, saveBusinessLicenseInfo, saveFileInfo, getMasterIsSpecial, newsImgBaseUrl } from "../../utils/api";
    import common from "@/utils/common";
    import {appAction, uwStatisticAction} from "@/utils/util";
    import { AUTH_SUBMIT } from "@/utils/constantNum";
    export default {
        name:'businessLicense',
        data () {
          return {
            ownerName:null,
            companyName: null,
            creditCode: '',
            fileList: [],
            fileNo: null,
            perviewImage:require('@/assets/img/stationagent/buinessLicenseBg.png'),
            isCanInput:false,
            noblImg:require('@/assets/img/stationagent/nobl.png'),
            fileListbl:[],
            isSpecial: 0,
            masterNo: '',
            stationNo: '',
            filePath: '',
            fileNobl:''
          }
        },
        watch: {
        },
        mounted () {
          uwStatisticAction('/stationagent/businessLicense','营业执照')
          getMasterIsSpecial({
            token: common.getItem('wtToken'),
          }).then(res=>{
            if(res.code == 200){
              if(res.data && res.data.businessCode == 200){
                this.isSpecial = res.data.data.isSpecial
                this.masterNo = res.data.data.masterNo
                this.stationNo = res.data.data.stationNo
              }
            }
          })
        },
        methods: {
            nameFormatter (val) {
              return val.replace(/[^\u4e00-\u9fa5.]/g, '')
            },
            choosenobl() {
              this.$refs.noblImgup.chooseFile()
            },
            chooseImg () {
              this.$refs.bankImgup.chooseFile()
            },
            afterReadbl(file){
              // 大于15M
              if(file.file.size > 15 * 1024 * 1024){
                  Toast('图片太大，请上传小于15M的图片')
                  return
              }
              if(!common.imageVerify(file.file.type)){
                  this.fileListbl.splice(detail.index, 1)
                  Toast('上传图片必须为png或者jpg格式')
                  return
              }
              // 小于100K不进行压缩
              if(file.file.size < 100 * 1024){
                  this.saveFileBl(file.content)
                  return
              }
              let reader = new FileReader()
              reader.readAsDataURL(file.file)
              let _this = this
              reader.onload = function (e) {
                  let content= file.content //图片的src，base64格式
                  let img = new Image()
                  img.src = content
                  _this.noblImg = content;
                  img.onload = function() {
                      common.compress(img, (dataURL)=>{
                          _this.saveFileBl(dataURL)
                          // console.log(dataURL)
                      })
                  }
              }
            },
            afterRead (file) {
              console.log(file)
              // 大于15M
              if(file.file.size > 15 * 1024 * 1024){
                  Toast('图片太大，请上传小于15M的图片')
                  return
              }
              if(!common.imageVerify(file.file.type)){
                  this.fileList.splice(detail.index, 1)
                  Toast('上传图片必须为png或者jpg格式')
                  return
              }
              // 小于100K不进行压缩
              if(file.file.size < 100 * 1024){
                  this.saveFile(file.content)
                  return
              }
              let reader = new FileReader()
              reader.readAsDataURL(file.file)
              let _this = this
              reader.onload = function (e) {
                  let content= file.content //图片的src，base64格式
                  let img = new Image()
                  img.src = content
                  _this.perviewImage = content;
                  img.onload = function() {
                      common.compress(img, (dataURL)=>{
                          _this.saveFile(dataURL)
                          // console.log(dataURL)
                      })
                  }
              }
            },
            saveFileBl(dataUrl){
              saveFileInfo({
                fileType: 'specialLicense',
                stationNo: this.stationNo,
                token: common.getItem('wtToken'),
                picOwnNo: this.masterNo, // 是站长是传masterNo；
                fileContent: dataUrl,
              }).then(res=>{
                if(res.code == 200){
                  if(res.data && res.data.businessCode == 200){
                    this.fileListbl = []
                    this.filePath = res.data.filePath
                    this.fileNobl = res.data.fileNo ? res.data.fileNo : ''
                    // this.noblImg = newsImgBaseUrl + '/' + this.filePath
                    // console.log(this.noblImg)
                  }else{
                    this.noblImg=require('@/assets/img/stationagent/nobl.png')
                  }
                }else{
                  this.noblImg=require('@/assets/img/stationagent/nobl.png')
                }
              })
            },
            saveFile(dataURL) {
                let params = {
                    fileContent: dataURL
                }
                sendBusinessLicenseOcr(params).then(res => {
                    this.fileList = [];
                    if (res.code === 200) {
                        // let data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        if (data.businessCode === 200) {
                            this.isCanInput = true;
                            if (data.companyName) {
                                this.companyName = data.companyName
                            }
                            this.creditCode = data.creditCode
                            this.ownerName = data.ownerName
                        } else {
                            this.perviewImage=require('@/assets/img/stationagent/buinessLicenseBg.png')
                        }
                        this.fileNo = data.fileNo ? data.fileNo : ''
                    }else {
                        this.perviewImage=require('@/assets/img/stationagent/buinessLicenseBg.png')
                    }
                }).catch(() => {
                    this.fileList = [];
                });
            },
            commitAction () {
              let p = {}
              if(this.isSpecial != 1){
                if (!this.isCanInput) {
                    this.$toast('请点击相机上传营业执照图片')
                    return;
                }
                if (!this.ownerName) {
                    this.$toast('请输入经营者/法定代表人')
                    return;
                }
                if (!this.companyName) {
                    this.$toast('请输入名称')
                    return
                }
                if (!this.creditCode) {
                    this.$toast('请输入统一社会信用代码')
                    return;
                }
                if (!this.fileNo) {
                    this.$toast('图片识别失败，请重新上传')
                    return
                }
                p ={
                    creditCode: this.creditCode,
                    ownerName: this.ownerName,
                    companyName: this.companyName,
                }
              }else{
                if(!this.fileNobl){
                  this.$toast('其他证明图片识别失败，请重新上传')
                  return
                }
              }
                
                saveBusinessLicenseInfo(p).then(res => {
                    if (res.code === 200) {
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        if (data.businessCode === 200) {
                            this.$router.push({name: 'cardInfo'})
                            if (common.getItem('jumpFrom') != undefined  ) {
                              appAction(AUTH_SUBMIT)
                            }
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
.card-info {
  background: #f5f5f5;
  height: 100%;
  font-size: 15px;
  box-sizing: border-box;
  .nobl{
    padding: 0.4rem 0.3rem;
    .bobl-title{
      font-size: 0.28rem;
      color: #9B9B9B;
    }
  }
  .card-title {
    padding: 0.3rem 0.3rem 0rem;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #333333;
  }
  .card-upload {
    margin: 0 0.3rem;
    height: 1.5rem;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    overflow: hidden;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .van-button {
        width: 100%;
    }
  }
}
</style>
